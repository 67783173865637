import React from "react"
import {
  Navigation,
  Blog,
  Footer,
  Hero,
  Breadcrumb,
} from "../../components/Marketing"
import { Seo } from "../../components/Seo/Seo"
import { graphql, Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"

export default ({ data }) => {
  const stats = data.allHaikusTsv.group
  const redirections = data.allRedirectionsTsv.edges
  const authors = data.allAuthorsTsv.nodes
    .map(author => ({
      ...author,
      url:
        redirections.find(({ node }) => node.id === author.id)?.node?.to ||
        `/recueils/auteurs/${author.id}/`,
      count: stats.find(({ author_id }) => author.id === author_id).count,
    }))
    .sort((before, after) => {
      return after.count - before.count
    })
  const illustration = getImage(data.illustration)

  const breadcrumbs = [
    { label: "Recueils de haïkus", url: "/recueils/" },
    { label: "Par auteurs", url: "/recueils/auteurs/" },
  ]

  return (
    <>
      <Seo
        title={`Recueils de haïkus par auteurs`}
        description={`La page des recueils de haïkus par auteurs regroupe l'ensemble des haïkus des anciens auteurs, classés par nombre de haïkus produits.`}
        breadcrumbs={breadcrumbs}
        image={illustration.images.fallback.src}
      />
      <Navigation />
      <Hero
        illustration={illustration}
        theme={{
          as: "div",
          value: <Breadcrumb items={breadcrumbs} />,
        }}
        title={{
          as: "h1",
          value: (
            <>
              Le recueil des <span className="text-green-600">haïkus</span> de
              nos ainés
            </>
          ),
        }}
        description={{
          value: (
            <>
              Le <strong>Temple du Haïku</strong> est un site qui permet à la
              communauté de publier et lire des haïkus. Bien que nous soyons des
              créateurs, nous pensons que nous partageons tous une passion pour
              la poésie et le haïku en général.
              <br />
              C'est pour cela que nous <strong>
                faisons notre maximum
              </strong>{" "}
              pour rendre hommage aux anciens haïkistes en publiant des pages
              dédiées à leur histoire et en publiant leurs créations.
              <br />
              Nous espérons que vous pourrez découvrir leurs créations et
              pourquoi pas qu'elles vous aideront trouver l'inspiration.
            </>
          ),
        }}
      />
      <Blog
        title={{
          as: "h3",
          value: (
            <>
              Liste des auteurs <br />
              classés par le nombre de{" "}
              <span className="text-green-600">haïkus</span> produits
            </>
          ),
        }}
        description={
          <>
            Cette liste d'auteurs n'est pas exhaustives et sera alimenté
            régulièrement.
          </>
        }
        articles={authors.map(({ name, id, count, url }) => ({
          category: "Recueil",
          url,
          title: name,
          description: `Le recueil des haïkus produits par ${name}`,
        }))}
      />

      <Footer />
    </>
  )
}

export const query = graphql`
  query GetAllAnthologyAuthors {
    illustration: file(relativePath: { eq: "illustrations/monk.jpeg" }) {
      id
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
          layout: CONSTRAINED
          width: 1200
          quality: 80
        )
      }
    }
    allAuthorsTsv {
      nodes {
        id
        name
      }
    }
    allHaikusTsv {
      group(field: author_id) {
        author_id: fieldValue
        count: totalCount
      }
    }
    allRedirectionsTsv {
      edges {
        node {
          id
          to
        }
      }
    }
  }
`
